@charset "UTF-8";

@font-face {
  font-family: 'CitiSans';
  src: url("../../../common/fonts/citisa.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'CitiSans';
  src: url("../../../common/fonts/citisa-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'CitiSans';
  src: url("../../../common/fonts/citisa-italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'CitiSans';
  src: url("../../../common/fonts/citisa-italicBold.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'FiraSans';
  src: url("../../../common/fonts/FiraSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'FiraSans';
  src: url("../../../common/fonts/FiraSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'FiraSans';
  src: url("../../../common/fonts/FiraSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
button,
input {
  border: 0;
  border-radius: 0;
  color: inherit;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  text-decoration: none;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: normal;
  font-family: "FiraSans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  flex-grow: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*,
::after,
::before {
  box-sizing: border-box;
}

* {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

html {
  -webkit-text-size-adjust: 100%;
  min-height: 100%;
  display: flex;
}

img,
svg {
  max-width: 100%;
  max-height: 100%;
}

button,
textarea {
  appearance: none;
}

abbr,
abbr[title] {
  text-decoration: none;
  border-bottom: none;
}

button {
  background: none;
  cursor: pointer;
}

/** @define Heading */

.Heading {
  font-family: "Arial";
}

.Heading--alpha {
  font-size: 27px;
  line-height: 36px;
}

.Heading--major {
  font-size: 28px;
  line-height: 28px;
  color: white;
  font-weight: 700;
}

@media (min-width: 768px) {
  .Heading--major {
    font-size: 35px;
  }
}

@media (min-width: 768px) {
  .Heading--major {
    line-height: 35px;
  }
}

.Heading--minor {
  font-size: 27px;
  line-height: 36px;
  color: white;
}

.Heading--sub {
  font-size: 18px;
  line-height: 18px;
  color: white;
  font-weight: 700;
}

.Heading--title {
  font-size: 22px;
  line-height: 26px;
  font-family: "FiraSans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/** @define Link */

.Link {
  color: #759b24;
  cursor: pointer;
  /* postcss-bem-linter: ignore */
}

.Link:hover {
  text-decoration: underline;
}

.Link--primary {
  /* postcss-bem-linter: ignore */
}

.Link--primary::after {
  content: url(../../../../assets/images/green_angle_right.svg);
  margin-left: 5px;
}

.Link--primarySmall {
  /* postcss-bem-linter: ignore */
}

.Link--primarySmall::after {
  content: url(../../../../assets/images/green_angle_right_small.svg);
  margin-left: 5px;
}

.Link--header {
  color: #005944;
  /* postcss-bem-linter: ignore */
  font-size: 16px;
  line-height: 24px;
}

.Link--header:hover {
  color: #759b24;
  /* postcss-bem-linter: ignore */
}

.Link--header:hover::before {
  background-image: url(../../../../assets/images/header-pinHover.svg);
}

@media (max-width: 767px) {
  .Link--header {
    display: none;
  }
}

.Link--headerSecondary {
  font-size: 18px;
  line-height: 22px;
  color: #044da2;
  display: flex;
  /* postcss-bem-linter: ignore */
  /* postcss-bem-linter: ignore */
}

@media (max-width: 767px) {
  .Link--headerSecondary {
    display: none;
  }
}

.Link--headerSecondary:hover {
  text-decoration: underline;
}

.Link--headerSecondary::after {
  content: "";
  display: block;
  width: 17px;
  height: 24px;
  background-image: url(../../../../assets/images/chevron.svg);
}

.Link--breadcrumbs {
  font-size: 12px;
  color: #044da2;
  /* postcss-bem-linter: ignore */
  /* postcss-bem-linter: ignore */
}

.Link--breadcrumbs:hover {
  text-decoration: underline;
}

.Link--breadcrumbs:active {
  color: #231f20;
}

.Link--body {
  font-size: 16px;
  line-height: 22px;
  color: #044da2;
  text-decoration: underline;
  /* postcss-bem-linter: ignore */
}

.Link--body:hover {
  text-decoration: none;
}

/** @define Button */

.Button {
  appearance: none;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  display: inline-block;
  text-decoration: none;
  white-space: nowrap;
}

.Button-primary {
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  background-color: #d93d00;
  color: white;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 14px;
  padding-bottom: 14px;
  display: flex;
  justify-content: space-around;
}

.Button-primary:hover {
  background-color: #ad3000;
}

.Button-primary:focus,
.Button-primary:active {
  color: #044da2;
}

.Button:link,
.Button:visited,
.Button:active,
.Button:hover,
.Button:focus {
  text-decoration: none;
}

.Button--clear {
  background-color: transparent;
  border: none;
}

.Button--cta {
  background-color: #008555;
  border-color: #008555;
  color: white;
}

.Button--cta:focus {
  outline: none;
}

.Button--cta:hover,
.Button--cta:focus {
  background-color: white;
  border-color: #008555;
  color: #008555;
}

.Button--inverted {
  background-color: transparent;
  border-color: white;
  color: white;
}

.Button--inverted:focus {
  outline: none;
}

.Button--inverted:hover,
.Button--inverted:focus {
  background-color: white;
  border-color: #008555;
  color: #008555;
}

.Button--invertedBrand {
  background-color: white;
  border-color: #008555;
  color: #008555;
}

.Button--invertedBrand:focus {
  outline: none;
}

.Button--invertedBrand:hover,
.Button--invertedBrand:focus {
  background-color: #008555;
  border-color: #008555;
  color: white;
}

.Button--tight {
  padding-top: 0.33333em;
  padding-bottom: 0.33333em;
  padding-left: 0.66667em;
  padding-right: 0.66667em;
}

.Button--loose {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-right: 1em;
  padding-left: 1em;
}

/** @define Text */

.Text {
  font-size: 16px;
  line-height: 22px;
  font-family: "FiraSans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #231f20;
}

/** @define Teaser */

.Teaser {
  margin-left: 35px;
}

.Teaser-title,
.Teaser-open,
.Teaser-address,
.Teaser-hours {
  margin-bottom: 0.5rem;
}

.Teaser-links {
  display: flex;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.Teaser-phone {
  /* postcss-bem-linter: ignore */
}

.Teaser-phone .c-phone-label {
  display: none;
}

.Teaser-directions {
  margin-right: 2rem;
}

/** @define ResultList */

.ResultList-item {
  border-bottom: 1px solid #e1e5e8;
  padding: 16px;
}

.ResultList-item:first-child {
  border-top: 1px solid #e1e5e8;
}

@media (min-width: 992px) {
  .ResultList-item:hover,
  .ResultList-item.is-hovered {
    background-color: #f7f7f7;
  }
}

@media (min-width: 992px) {
  .ResultList-item.is-selected {
    background-color: #f2f1f3;
  }
}

@media (min-width: 992px) {
  .ResultList-item--ordered {
    counter-increment: resultCount;
    position: relative;
    padding-left: 15px;
  }

  .ResultList-item--ordered::before {
    content: counter(resultCount, decimal) ".";
    position: absolute;
    left: 15px;
    line-height: 36px;
  }
}

/** @define Header */

.Header {
  height: 70px;
  display: flex;
  width: 100%;
}

@media (min-width: 992px) {
  .Header {
    height: 110px;
  }
}

.Header-main {
  display: flex;
  padding: 20px;
}

@media (min-width: 992px) {
  .Header-main {
    padding: 40px;
  }
}

.Header-divider {
  margin-left: 20px;
  margin-right: 20px;
  border-right: 1px solid #008555;
}

.Header-logoLink {
  display: flex;
}

.Header-logoImage {
  height: 30px;
  object-fit: contain;
}

@media (min-width: 768px) {
  .Header-logoImage {
    height: 36px;
  }
}

@media (max-width: 767px) {
  .Header-link1Wrapper {
    display: none;
  }
}

@media (min-width: 768px) {
  .Header-link1Wrapper {
    width: 359px;
    height: 92px;
    background-image: url(../../../../assets/images/accent.svg);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .Header-bottomRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .Header-bottomRow {
    width: 720px;
  }
}

@media (min-width: 768px) and (min-width: 992px) {
  .Header-bottomRow {
    width: 940px;
  }
}

@media (min-width: 768px) and (min-width: 1200px) {
  .Header-bottomRow {
    width: 1140px;
  }
}

@media (max-width: 767px) {
  .Header-bottomRow {
    display: none;
  }
}

@media (min-width: 768px) {
  .Header-mapPin,
  .Header-officer {
    display: none;
  }
}

/** @define Main */

.Main {
  width: 100%;
}

@media (min-width: 768px) {
  .Main {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
  }

  .Main-breadcrumbs {
    order: -99;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .Main-breadcrumbs--mobile {
    display: none;
  }
}

@media (max-width: 767px) {
  .Main-breadcrumbs {
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 20px;
  }
}

.Main-content {
  width: 100%;
}

/** @define Footer */

.Footer {
  flex-shrink: 0;
}

.Footer-container {
  padding: 15px;
}

.Footer-block:not(:last-child) {
  font-size: 16px;
  line-height: 22px;
  font-family: "FiraSans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #231f20;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 15px;
}

.Footer-fdic {
  margin-left: 8px;
}

/** @define AutoCard */

.AutoCard {
  width: 100%;
  padding-top: 4px;
  padding-right: 8px;
  padding-left: 8px;
  padding-bottom: 4px;
}

.AutoCard:first-child {
  padding-top: 8px;
}

.AutoCard:last-child {
  padding-bottom: 8px;
}

.AutoCard-wrapper {
  display: flex;
  flex-grow: 1;
}

.AutoCard-imageWrapper {
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.AutoCard-imageWrapper--default {
  width: 50px;
  height: 50px;
  justify-content: center;
  background-color: #f2f1f3;
  /* postcss-bem-linter: ignore */
}

.AutoCard-imageWrapper--default .fa-user {
  font-size: 40px;
}

.AutoCard-image {
  width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 2px;
}

.AutoCard-name {
  font-size: 18px;
  font-weight: 700;
}

.AutoCard-id,
.AutoCard-address {
  margin-top: 5px;
}

.AutoCard-hidden {
  display: none;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  font-style: italic;
  color: #231f20;
}

/* postcss-bem-linter: ignore */

.ui-state-active {
  /* postcss-bem-linter: ignore */
}

.ui-state-active .AutoCard {
  /* postcss-bem-linter: ignore */
}

@media (min-width: 768px) {
  .ui-state-active .AutoCard-hidden {
    display: flex;
  }
}

/** @define AutoItem */

.AutoItem {
  width: 100%;
}

.AutoItem-wrapper {
  padding-top: 4px;
  padding-right: 8px;
  padding-left: 8px;
  padding-bottom: 4px;
  display: flex;
  flex-grow: 1;
}

.AutoItem-hidden {
  display: none;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  font-style: italic;
  color: #231f20;
}

/* postcss-bem-linter: ignore */

.ui-state-active {
  /* postcss-bem-linter: ignore */
}

.ui-state-active .AutoItem {
  /* postcss-bem-linter: ignore */
}

@media (min-width: 768px) {
  .ui-state-active .AutoItem-hidden {
    display: flex;
  }
}

/** @define LocationCard */

.LocationCard {
  width: 25%;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #ebf7f5;
  padding-top: 16px;
  padding-bottom: 16px;
  /* postcss-bem-linter: ignore */
  /* postcss-bem-linter: ignore */
}

@media (min-width: 768px) and (max-width: 991px) {
  .LocationCard {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .LocationCard {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) {
  .LocationCard {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) {
  .LocationCard {
    max-width: 260px;
  }
}

@media (max-width: 991px) {
  .LocationCard {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .LocationCard {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .LocationCard:not(:last-child) {
    margin-right: 40px;
  }
}

@media (max-width: 767px) {
  .LocationCard--directory {
    margin-bottom: 0;
  }
}

.LocationCard-detail {
  flex-grow: 1;
}

.LocationCard-title {
  font-size: 18px;
  line-height: 22px;
  color: #044da2;
  display: flex;
  /* postcss-bem-linter: ignore */
  /* postcss-bem-linter: ignore */
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

@media (max-width: 767px) {
  .LocationCard-title {
    display: none;
  }
}

.LocationCard-title:hover {
  text-decoration: underline;
}

.LocationCard-title::after {
  content: "";
  display: block;
  width: 17px;
  height: 24px;
  background-image: url(../../../../assets/images/chevron.svg);
}

@media (max-width: 767px) {
  .LocationCard-title {
    display: flex;
    justify-content: flex-start;
  }
}

.LocationCard-nmls {
  margin-bottom: 8px;
  font-weight: 700;
}

.LocationCard-headshotWrapper {
  display: flex;
  align-items: center;
}

.LocationCard-imageWrapper {
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.LocationCard-image {
  width: 64px;
  min-width: 50px;
  height: 64px;
  border-radius: 32px;
  border: 1px solid black;
}

.LocationCard .c-location-hours-today {
  font-size: 16px;
  line-height: 22px;
  font-family: "FiraSans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #231f20;
  font-family: "Arial";
  font-weight: 700;
  margin-bottom: 8px;
}

.LocationCard .c-AddressRow:last-child {
  display: none;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  white-space: nowrap;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

.xYextDebug {
  --settings-accent: #178acd;
  --settings-text: white;
  --tooltip-background: #178acd;
  --tooltip-text: white;
  --scope-background: rgba(0, 0, 0, 0.45);
  --scope-text: white;
}

.xYextDebug a:not(.no-tooltip),
.xYextDebug button:not(.no-tooltip),
.xYextDebug input:not(.no-tooltip) {
  outline: 1px dashed blue;
}

.xYextDebug [data-ya-track] {
  outline: 1px solid blue;
}

.xYextDebug [data-ya-scope] {
  outline: 1px dashed green;
}

.xYextDebug .Highlight {
  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  outline: 2px dashed black;
  padding: 8px 16px;
}

.xYextDebug .Tooltip {
  background: var(--tooltip-background);
  color: var(--tooltip-text);
  position: absolute;
  padding: 5px 10px;
  border-radius: 4px;
  transition: all 300ms ease-out;
  font-size: 12px;
  z-index: 99998;
}

img[src*="www.yext-pixel.com"] {
  position: absolute;
}

@keyframes pulse {
  0% {
    background: transparent;
  }

  50% {
    background: rgba(0, 0, 0, 0.15);
  }

  100% {
    background: transparent;
  }
}

.Analytics {
  position: fixed;
  height: 80%;
  top: 10%;
  bottom: 10%;
  right: -250px;
  transition: right 300ms ease-out;
  width: 350px;
  color: var(--settings-text);
  display: flex !important;
  z-index: 99999;
}

.Analytics.show {
  right: 0;
}

.Analytics-toggles {
  width: 100px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background: rgba(0, 0, 0, 0.75);
  margin: auto;
  display: flex;
  flex-direction: column;
  border-right: 1px solid white;
}

.Analytics-toggles--item {
  outline: none;
  text-align: center;
  padding: 10px;
}

.Analytics-toggles--item:not(:last-child) {
  border-bottom: 1px solid white;
}

.Analytics-toggles--item:hover {
  cursor: pointer;
}

.Analytics-toggles--item:hover,
.Analytics-toggles--item.active {
  background: var(--settings-accent);
}

.Analytics-toggles--item:hover:first-child,
.Analytics-toggles--item.active:first-child {
  border-top-left-radius: 4px;
}

.Analytics-toggles--item:hover:last-child,
.Analytics-toggles--item.active:last-child {
  border-bottom-left-radius: 4px;
}

.Analytics-tabs {
  width: 250px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background: rgba(0, 0, 0, 0.75);
}

.Analytics-tabs--footerToggle {
  min-height: 46px;
  width: 100%;
  text-align: left;
  padding: 10px 20px;
  border-top: 1px solid white;
  border-bottom-left-radius: 4px;
  outline: none;
}

.Analytics-tabs--footerToggle:not(.active):hover {
  background: rgba(255, 255, 255, 0.3);
}

.Analytics-tabs--footerToggle:hover {
  cursor: pointer;
}

.Analytics-tabs--footerToggle.active {
  background: var(--settings-accent);
}

.Analytics-tabs--title {
  background: var(--settings-accent);
  color: var(--settings-text);
  padding: 20px;
  border-bottom: 1px solid white;
  border-top-left-radius: 4px;
}

.Analytics-tabs--search {
  outline: none;
  background: transparent;
  padding: 10px 20px;
  border-bottom: 1px solid white;
  width: 100%;
}

.Analytics-tabs--item {
  display: none;
}

.Analytics-tabs--item.active {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Analytics-list {
  overflow-y: scroll;
  flex-grow: 1;
}

.Analytics-list--item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.Analytics-list--item:not(:last-child) {
  border-bottom: 1px solid white;
}

.Settings-input {
  display: none;
  outline: none;
  width: 100%;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
  background: transparent;
  border-bottom: 1px solid white;
}

.edit .Settings-input {
  display: block;
}

.Settings-toggle {
  display: flex;
  justify-content: space-between;
  outline: none;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
}

.Settings-toggle:hover {
  background: rgba(255, 255, 255, 0.3);
  cursor: pointer;
}

.Settings-tbc,
.Settings-ttc,
.Settings-sbc,
.Settings-stc,
.Settings-accent,
.Settings-text {
  height: 14px;
  width: 14px;
  border: 1px solid white;
}

.Settings-tbc {
  background: var(--tooltip-background);
}

.Settings-ttc {
  background: var(--tooltip-text);
}

.Settings-accent {
  background: var(--settings-accent);
}

.Settings-text {
  background: var(--settings-text);
}

.Settings-sbc {
  background: var(--scope-background);
}

.Settings-stc {
  background: var(--scope-text);
}

.EventNameTab-button {
  padding: 10px 20px;
  text-align: left;
  width: 100%;
}

.EventNameTab-button--active {
  background: var(--settings-accent);
  outline: none;
}

.EventNameTab-button:not(.EventNameTab-button--active):hover {
  background: rgba(255, 255, 255, 0.3);
  cursor: pointer;
}

.Scope {
  position: absolute;
  padding: 10px;
  outline: 1px solid var(--settings-accent);
  background: var(--scope-background);
  color: var(--scope-text);
}

/** @define About */

.About--ace {
  padding-bottom: 4rem;
}

.About--ace .About-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
}

.About--ace .About-title,
.About--ace .About-content,
.About--ace .About-buttonWrapper {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.About--ace .About-title {
  text-align: center;
}

.About--ace .About-content {
  margin-top: 2rem;
}

@media (min-width: 768px) and (max-width: 991px) {
  .About--ace .About-description {
    width: 66.66667%;
  }
}

@media (min-width: 768px) {
  .About--ace .About-description {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  .About--ace .About-description {
    width: 50%;
  }
}

.About--ace .About-buttonWrapper {
  text-align: center;
  margin-top: 2rem;
}

/** @define Directory */

.Directory--ace {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-bottom: 4rem;
}

@media (min-width: 768px) {
  .Directory--ace {
    width: 720px;
  }
}

@media (min-width: 992px) {
  .Directory--ace {
    width: 940px;
  }
}

@media (min-width: 1200px) {
  .Directory--ace {
    width: 1140px;
  }
}

.Directory--ace .Directory-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
}

.Directory--ace .Directory-title {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 2rem;
}

.Directory--ace .Directory-content {
  margin-top: 2rem;
}

@media (max-width: 767px) {
  .Directory--ace .Directory-listTeaser {
    width: 100%;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .Directory--ace .Directory-listTeaser:not(:last-child) {
    border-bottom: 1px solid #666;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
  }
}

@media (min-width: 768px) {
  .Directory--ace .Directory-listTeaser {
    width: 50%;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 2rem;
  }
}

@media (min-width: 992px) {
  .Directory--ace .Directory-listTeaser {
    width: 33%;
  }
}

@media (min-width: 1200px) {
  .Directory--ace .Directory-listTeaser {
    width: 25%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Directory--ace .Directory-listLinks {
    column-count: 2;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .Directory--ace .Directory-listLinks {
    column-count: 3;
  }
}

@media (min-width: 1200px) {
  .Directory--ace .Directory-listLinks {
    column-count: 4;
  }
}

.Directory--ace .Directory-listItem {
  text-transform: uppercase;
}

.Directory--ace .Directory-listLink {
  display: inline-block;
  margin-bottom: 1rem;
}

.Directory--ace .Directory-listLink::after {
  content: attr(data-count);
  margin-left: 1ch;
}

/** @define Locator */

.Locator--ace {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  /* postcss-bem-linter: ignore */
}

@media (min-width: 992px) {
  .Locator--ace {
    position: fixed;
    padding-top: 110px;
  }
}

.Locator--ace .Locator {
  /* postcss-bem-linter: ignore */
  /* postcss-bem-linter: ignore */
}

.Locator--ace .Locator-title {
  padding: 16px;
}

.Locator--ace .Locator-email {
  color: #005944;
  text-decoration: underline;
}

.Locator--ace .Locator-email:hover {
  text-decoration: none;
}

.Locator--ace .Locator-buttons {
  display: flex;
}

.Locator--ace .Locator-button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 44px;
  height: 44px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 8px;
  padding-right: 8px;
  /* postcss-bem-linter: ignore */
}

.Locator--ace .Locator-button[disabled] {
  background-color: #e7e7e7;
  cursor: default;
}

.Locator--ace .Locator-button:not(:last-child) {
  margin-right: 1px;
}

.Locator--ace .Locator-button:not(:first-child) {
  margin-left: 1px;
}

.Locator--ace .Locator-button .icon {
  width: 18px;
  height: 18px;
}

.Locator--ace .Locator-inputWrapper {
  display: flex;
  flex-grow: 1;
}

.Locator--ace .Locator-input {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
  border-top: 1px solid #666;
  border-bottom: 1px solid #666;
  border-left: 1px solid #666;
  height: 44px;
  width: 100%;
}

.Locator--ace .Locator-searchTitle {
  padding-left: 16px;
  padding-right: 16px;
}

.Locator--ace .Locator-results {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  overflow: auto;
}

.Locator--ace .Locator-searchWrapper {
  position: sticky;
  z-index: 1;
  padding: 16px;
  display: flex;
  flex-shrink: 0;
  width: 100%;
  top: 0;
  background-color: white;
}

.Locator--ace .Locator-searchWrapper::after {
  content: "";
  z-index: -1;
  display: block;
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: -3px;
  left: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
}

.Locator--ace .Locator-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s linear, opacity .3s linear;
  z-index: 2;
}

.Locator--ace .Locator-overlay.is-active {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear, opacity .3s linear;
}

.Locator--ace .Locator-map {
  display: none;
}

@media (min-width: 992px) {
  .Locator--ace .Locator-map {
    display: block;
    position: fixed;
    width: calc(100% - 584px);
    height: calc(100vh - 110px);
    right: 0;
  }
}

.Locator--ace .Locator-resultsWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.Locator--ace .Locator-resultsSummary {
  padding: 16px;
}

.Locator--ace .Locator-content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: white;
}

@media (min-width: 992px) {
  .Locator--ace .Locator-content {
    position: relative;
    bottom: 0;
    height: auto;
    width: 584px;
    overflow-y: scroll;
  }
}

.Locator--ace .Locator-initial,
.Locator--ace .Locator-noResults {
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 80%;
  font-size: 16px;
  line-height: 24px;
}

.Locator--ace .Locator-filterFieldsWrapper {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity .3s linear;
  z-index: 2;
}

@media (min-width: 992px) {
  .Locator--ace .Locator-filterFieldsWrapper {
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translate(-50%, -50%);
  }
}

.Locator--ace .Locator-filters.is-active {
  /* postcss-bem-linter: ignore */
}

.Locator--ace .Locator-filters.is-active .Locator-filterFieldsWrapper {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity .3s linear;
}

.Locator--ace .Locator-filterFields {
  width: 100%;
}

@media (min-width: 992px) {
  .Locator--ace .Locator-filterFields {
    width: 400px;
    min-height: 252px;
    max-height: 460px;
  }
}

.Locator--ace .Locator-filtersCount {
  margin-left: 3px;
}

.Locator--ace .Locator-form {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.Locator--ace .c-map-with-pins-wrapper,
.Locator--ace .c-map-with-pins {
  height: 100%;
  width: 100%;
}

/** @define LocatorFilters */

.LocatorFilters {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: white;
}

.LocatorFilters-content {
  display: flex;
  flex-direction: column;
}

.LocatorFilters-content label {
  margin-bottom: 20px;
}

.LocatorFilters-titleRow {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  font-weight: 700;
  margin-bottom: 16px;
}

.LocatorFilters-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 1px;
}

.LocatorFilters-content label {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-bottom: 20px;
}

.LocatorFilters-content input {
  margin-right: 8px;
}

.LocatorFilters-close {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  margin-left: 8px;
}

.LocatorFilters-close:focus {
  outline: 1px dashed black;
}

.LocatorFilters-close::-moz-focus-inner {
  outline: 0;
  border: 0;
}

.LocatorFilters-buttons {
  display: flex;
  flex-shrink: 0;
  padding-top: 8px;
}

.LocatorFilters-button {
  display: flex;
  flex-basis: 50%;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.LocatorFilters-button[disabled],
.LocatorFilters-button:hover[disabled] {
  background-color: #e7e7e7;
  cursor: default;
}

.LocatorFilters-button:not(:first-child) {
  margin-left: 8px;
}

html.ace {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

html.ace body {
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

html.ace body.is-overflowHidden {
  overflow-y: hidden;
}

html.ace .Main-content {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

html.ace .Main {
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

html.ace .Footer {
  flex-shrink: 0;
}

html.ace .Header {
  display: block;
  top: 0;
  right: 0;
  left: 0;
  height: 70px;
  z-index: 2;
}

@media (min-width: 768px) and (max-width: 991px) {
  html.ace .Header {
    height: 70px;
  }
}

@media (min-width: 992px) {
  html.ace .Header {
    position: fixed;
    height: 110px;
  }
}

html.ace .Header.is-background {
  z-index: 1;
}

html.ace .SpinnerModal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (min-width: 768px) and (max-width: 991px) {
  html.ace .SpinnerModal {
    top: 70px;
  }
}

@media (min-width: 992px) {
  html.ace .SpinnerModal {
    top: 110px;
    width: 584px;
    right: auto;
  }
}

html.ace .custom-icon-button {
  transition: transform 0.5s;
  transform-origin: 50% 100%;
}

html.ace .custom-icon-button:hover,
html.ace .custom-icon-button.is-selected {
  outline: none;
}

html.ace .custom-icon-button.is-selected {
  transform: scale(1.1);
}

html.ace .pac-container {
  top: 40px !important;
  left: 0 !important;
}

.c-description.show-full-description .c-description-truncated {
  display: none;
}

.c-description.show-full-description .c-description-full {
  display: block;
}

.c-description-full {
  display: none;
}

.c-events-event {
  margin-bottom: 16px;
}

.c-events-event-details-date {
  margin-bottom: 0px;
}

.c-events-event-details-title {
  margin-bottom: 0px;
}

.c-events-event-details-description {
  margin-top: 10px;
}

.c-events-event-details-description.show-full-description .c-events-section-event-details-description-truncated {
  display: none;
}

.c-events-event-details-description.show-full-description .c-events-section-event-details-description-full {
  display: block;
}

.c-events-event-details-description-full {
  display: none;
}

.ObjectFit {
  object-fit: cover;
}

.ObjectFit-container {
  display: flex;
  align-items: flex-start;
  background-size: cover;
}

.ObjectFit-container .ObjectFit {
  opacity: 0;
}

.c-instagram-photo-wrapper {
  max-width: 100%;
}

.c-instagram-photo-info {
  margin-top: 6px;
  margin-bottom: 12px;
}

.c-instagram-photo-info .fa {
  margin-right: 4px;
}

.c-instagram-photo-info-likes {
  margin-right: 20px;
}

.c-location-hours:before,
.c-location-hours:after {
  content: " ";
  display: table;
}

.c-location-hours:after {
  clear: both;
}

.c-location-hours-details-row.is-today.highlight-text {
  font-weight: 700;
}

.c-location-hours-details-row.is-today.highlight-background {
  background-color: #D8D8D8;
}

.c-location-hours-details-row-day {
  padding-right: 20px;
}

.c-location-hours-details-row-intervals-instance {
  display: block;
}

.c-location-hours-details-opentoday {
  display: none;
}

.c-location-hours-today {
  display: block;
}

.c-location-hours-today-details-row {
  display: none;
}

.c-location-hours-today-details-row.is-today {
  display: block;
}

.c-location-hours-today-day-hours {
  display: inline-block;
}

.c-location-hours-today-day-hours-interval-separator {
  display: inline-block;
}

.c-location-hours-today-day-hours-intervals-instance {
  display: inline-block;
}

.c-location-hours-collapse .c-hours-toggle {
  display: inline-block;
}

.c-location-hours-collapse .c-hours-toggle:after {
  content: "";
  font-family: 'FontAwesome';
  font-size: inherit;
  display: inline-block;
  transform: rotateX(180deg);
  transition: transform .5s;
  vertical-align: middle;
  margin-left: 1ex;
}

.c-location-hours-collapse .c-hours-toggle.collapsed:after {
  transform: rotate(0);
}

.c-location-hours-collapse .c-location-hours-today,
.c-location-hours-collapse .c-location-hours-today-details-row.is-today {
  display: inline-block;
}

.c-map-with-pins {
  position: relative;
  width: 100%;
  height: 400px;
}

.c-map-with-pins img {
  max-width: none;
  max-height: none;
}

.c-map-with-pins .dir-map,
.c-map-with-pins #dir-map {
  width: 100%;
  height: 100%;
  line-height: initial;
}

.c-map-with-pins .dir-map *,
.c-map-with-pins #dir-map * {
  box-sizing: content-box;
}

.c-map-with-pins .dir-map .MicrosoftMap,
.c-map-with-pins #dir-map .MicrosoftMap {
  margin: 0;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: auto !important;
  height: auto !important;
}

.mapbox-improve-map {
  display: none;
}

.mapboxgl-ctrl-group .mapboxgl-ctrl-compass {
  display: none;
}

.custom-icon-button {
  background: transparent;
}

.gm-style img {
  max-width: none;
  max-height: none;
}

.c-reviews-form-wrapper {
  position: relative;
}

.c-reviews-form-wrapper .spinner {
  position: absolute !important;
  top: 50%;
  left: 50%;
}

.c-reviews-form-label {
  display: block;
}

.c-reviews-form-text-input {
  display: block;
  width: 100%;
  max-width: 100%;
  border: 1px solid black;
}

.c-reviews-form-privacy-check {
  display: inline-block;
  vertical-align: top;
  margin-top: 0.3rem;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  width: 2rem;
}

.c-reviews-form-terms-policy {
  display: inline-block;
  width: calc(100% - 2rem);
}

.c-reviews-form-star-rating {
  position: relative;
  display: block;
  border: none;
  margin: 0;
  font-size: 0;
  white-space: nowrap;
  width: 18rem;
  max-width: 100%;
  height: 3rem;
}

.c-reviews-form-star-rating-stars-wrapper {
  display: flex;
  flex-direction: row-reverse;
}

.c-reviews-form-star-rating input {
  appearance: none;
}

.c-reviews-form-star-rating input:checked ~ .c-reviews-form-star > svg {
  fill: #FFDF00;
}

.c-reviews-form-star-rating svg {
  width: 3.125rem;
  height: 3rem;
  fill: #FFFFFF;
  stroke: #CCAC00;
  stroke-width: 3;
}

.c-reviews-form-star {
  display: inline-block;
  height: 100%;
  width: 20%;
}

.c-reviews-form-star:hover > svg,
.c-reviews-form-star:hover ~ .c-reviews-form-star > svg {
  fill: #FFDF00;
}

.c-ReviewsList {
  list-style: none;
  padding: 0;
  z-index: 1;
}

.c-ReviewsSummary {
  display: flex;
}

.c-ReviewsSummary-number {
  margin-right: 15px;
}

.c-ReviewsSummary-stars {
  margin-right: 15px;
}

.c-ReviewStars {
  color: #FFDA27;
  margin-right: 10px;
}

.c-ReviewStars-star {
  font-size: 26px;
  margin-right: 5px;
}

.c-Reviews .slick-disabled {
  visibility: hidden;
}

@media (max-width: 767px) {
  .c-Reviews .slick-disabled {
    display: none !important;
  }
}

.c-Reviews .slick-disabled + .c-ReviewsControls-linkSeparator {
  display: none !important;
}

.c-Reviews .c-ReviewsControls-linkSeparator {
  margin-right: 15px;
}

.c-Review {
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid #9B9B9B;
}

.c-Review-body {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

@media (max-width: 767px) {
  .c-Review-body {
    flex-direction: column;
  }
}

.c-Review-stars {
  letter-spacing: 5px;
}

@media (max-width: 767px) {
  .c-Review-stars {
    order: 3;
  }
}

@media (max-width: 991px) {
  .c-Review-date {
    order: 2;
  }
}

@media (max-width: 991px) {
  .c-Review-content {
    order: 4;
  }
}

.c-Review-author,
.c-Review-date {
  width: 180px;
}

@media (max-width: 991px) {
  .c-Review-author,
  .c-Review-date {
    width: 100%;
  }
}

.c-Review-stars,
.c-Review-content {
  width: calc(100% - 180px);
}

@media (max-width: 991px) {
  .c-Review-stars,
  .c-Review-content {
    width: 100%;
  }
}

.c-Review-comment {
  width: calc(100% - 180px);
  margin-left: calc(180px + 10px);
  border-left: 3px solid black;
  padding-left: 20px;
}

@media (max-width: 991px) {
  .c-Review-comment {
    width: 100%;
    margin-left: 10px;
  }
}

.c-ReviewsPaging {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 5;
}

@media (max-width: 767px) {
  .c-ReviewsPaging {
    flex-direction: column;
  }
}

.c-ReviewsControls-link {
  border: none;
  background-color: transparent;
  padding: 0;
}

.c-ReviewsControls-link:focus {
  outline: none;
}

.c-ReviewsControls-link--previous {
  margin-right: 15px;
}

.c-ReviewsControls-link--previous .fa:before {
  padding-right: 5px;
}

.c-ReviewsControls-link--next .fa:before {
  padding-left: 5px;
}

/** @define SpinnerModal */

.SpinnerModal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0;
  visibility: hidden;
}

.SpinnerModal-parent {
  position: relative;
}

.SpinnerModal-image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%);
}

.SpinnerModal--visible {
  visibility: visible;
  z-index: 10;
  opacity: 0.7;
  transition: visibility 0s linear 100ms, opacity 700ms;
}

/*
 * This file adds a color map and a color function for quick hierarchical access
 * to colors, e.g. color: color(gray, one) or color(brand, red).  This provides
 * a bit more safety and context than using a raw variable.
*/

/** example
* $colors: (
*   grey: (
*     one: #c3c4c5,
*   ),
*   silver: (
*     one: #dbdcdc,
*   ),
* )
*/

/** @define List */

.search-input::-webkit-calendar-picker-indicator {
  display: none;
}

@media (max-width: 767px) {
  .col-sm-12.col-spaced,
  .col-sm-11.col-spaced,
  .col-sm-10.col-spaced,
  .col-sm-9.col-spaced,
  .col-sm-8.col-spaced,
  .col-sm-7.col-spaced,
  .col-sm-6.col-spaced,
  .col-sm-5.col-spaced,
  .col-sm-4.col-spaced,
  .col-sm-3.col-spaced,
  .col-sm-2.col-spaced,
  .col-sm-1.col-spaced {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .col-sm-12.col-spaced-top,
  .col-sm-11.col-spaced-top,
  .col-sm-10.col-spaced-top,
  .col-sm-9.col-spaced-top,
  .col-sm-8.col-spaced-top,
  .col-sm-7.col-spaced-top,
  .col-sm-6.col-spaced-top,
  .col-sm-5.col-spaced-top,
  .col-sm-4.col-spaced-top,
  .col-sm-3.col-spaced-top,
  .col-sm-2.col-spaced-top,
  .col-sm-1.col-spaced-top {
    margin-top: 16px;
  }

  .col-sm-12.col-spaced-bottom,
  .col-sm-11.col-spaced-bottom,
  .col-sm-10.col-spaced-bottom,
  .col-sm-9.col-spaced-bottom,
  .col-sm-8.col-spaced-bottom,
  .col-sm-7.col-spaced-bottom,
  .col-sm-6.col-spaced-bottom,
  .col-sm-5.col-spaced-bottom,
  .col-sm-4.col-spaced-bottom,
  .col-sm-3.col-spaced-bottom,
  .col-sm-2.col-spaced-bottom,
  .col-sm-1.col-spaced-bottom {
    margin-bottom: 16px;
  }
}

@media (max-width: 991px) {
  .col-md-12.col-spaced,
  .col-md-11.col-spaced,
  .col-md-10.col-spaced,
  .col-md-9.col-spaced,
  .col-md-8.col-spaced,
  .col-md-7.col-spaced,
  .col-md-6.col-spaced,
  .col-md-5.col-spaced,
  .col-md-4.col-spaced,
  .col-md-3.col-spaced,
  .col-md-2.col-spaced,
  .col-md-1.col-spaced {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .col-md-12.col-spaced-top,
  .col-md-11.col-spaced-top,
  .col-md-10.col-spaced-top,
  .col-md-9.col-spaced-top,
  .col-md-8.col-spaced-top,
  .col-md-7.col-spaced-top,
  .col-md-6.col-spaced-top,
  .col-md-5.col-spaced-top,
  .col-md-4.col-spaced-top,
  .col-md-3.col-spaced-top,
  .col-md-2.col-spaced-top,
  .col-md-1.col-spaced-top {
    margin-top: 16px;
  }

  .col-md-12.col-spaced-bottom,
  .col-md-11.col-spaced-bottom,
  .col-md-10.col-spaced-bottom,
  .col-md-9.col-spaced-bottom,
  .col-md-8.col-spaced-bottom,
  .col-md-7.col-spaced-bottom,
  .col-md-6.col-spaced-bottom,
  .col-md-5.col-spaced-bottom,
  .col-md-4.col-spaced-bottom,
  .col-md-3.col-spaced-bottom,
  .col-md-2.col-spaced-bottom,
  .col-md-1.col-spaced-bottom {
    margin-bottom: 16px;
  }
}

@media (max-width: 1199px) {
  .col-lg-12.col-spaced,
  .col-lg-11.col-spaced,
  .col-lg-10.col-spaced,
  .col-lg-9.col-spaced,
  .col-lg-8.col-spaced,
  .col-lg-7.col-spaced,
  .col-lg-6.col-spaced,
  .col-lg-5.col-spaced,
  .col-lg-4.col-spaced,
  .col-lg-3.col-spaced,
  .col-lg-2.col-spaced,
  .col-lg-1.col-spaced {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .col-lg-12.col-spaced-top,
  .col-lg-11.col-spaced-top,
  .col-lg-10.col-spaced-top,
  .col-lg-9.col-spaced-top,
  .col-lg-8.col-spaced-top,
  .col-lg-7.col-spaced-top,
  .col-lg-6.col-spaced-top,
  .col-lg-5.col-spaced-top,
  .col-lg-4.col-spaced-top,
  .col-lg-3.col-spaced-top,
  .col-lg-2.col-spaced-top,
  .col-lg-1.col-spaced-top {
    margin-top: 16px;
  }

  .col-lg-12.col-spaced-bottom,
  .col-lg-11.col-spaced-bottom,
  .col-lg-10.col-spaced-bottom,
  .col-lg-9.col-spaced-bottom,
  .col-lg-8.col-spaced-bottom,
  .col-lg-7.col-spaced-bottom,
  .col-lg-6.col-spaced-bottom,
  .col-lg-5.col-spaced-bottom,
  .col-lg-4.col-spaced-bottom,
  .col-lg-3.col-spaced-bottom,
  .col-lg-2.col-spaced-bottom,
  .col-lg-1.col-spaced-bottom {
    margin-bottom: 16px;
  }
}

@media (min-width: 768px) {
  .col-sm-12.col-padded,
  .col-sm-11.col-padded,
  .col-sm-10.col-padded,
  .col-sm-9.col-padded,
  .col-sm-8.col-padded,
  .col-sm-7.col-padded,
  .col-sm-6.col-padded,
  .col-sm-5.col-padded,
  .col-sm-4.col-padded,
  .col-sm-3.col-padded,
  .col-sm-2.col-padded,
  .col-sm-1.col-padded {
    padding: 16px;
  }
}

@media (min-width: 992px) {
  .col-md-12.col-padded,
  .col-md-11.col-padded,
  .col-md-10.col-padded,
  .col-md-9.col-padded,
  .col-md-8.col-padded,
  .col-md-7.col-padded,
  .col-md-6.col-padded,
  .col-md-5.col-padded,
  .col-md-4.col-padded,
  .col-md-3.col-padded,
  .col-md-2.col-padded,
  .col-md-1.col-padded {
    padding: 16px;
  }
}

@media (min-width: 1200px) {
  .col-lg-12.col-padded,
  .col-lg-11.col-padded,
  .col-lg-10.col-padded,
  .col-lg-9.col-padded,
  .col-lg-8.col-padded,
  .col-lg-7.col-padded,
  .col-lg-6.col-padded,
  .col-lg-5.col-padded,
  .col-lg-4.col-padded,
  .col-lg-3.col-padded,
  .col-lg-2.col-padded,
  .col-lg-1.col-padded {
    padding: 16px;
  }
}

.row-separator {
  border-bottom: solid 1px #222;
}

.row-vertical-margin-top,
.row-vertical-margin {
  margin-top: 32px;
}

.row-vertical-margin-top-sm,
.row-vertical-margin-sm {
  margin-top: 16px;
}

.row-vertical-margin-bottom,
.row-vertical-margin {
  margin-bottom: 32px;
}

.row-vertical-margin-bottom-sm,
.row-vertical-margin-sm {
  margin-bottom: 16px;
}

@media (min-width: 1200px) {
  .row-flex-lg {
    display: flex;
    flex-pack: distribute;
    flex-direction: row;
  }

  .col-flex-lg {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  .col-flex-lg .col-flex-child {
    flex-grow: 1;
  }
}

@media (min-width: 992px) {
  .row-flex-md {
    display: flex;
    flex-pack: distribute;
    flex-direction: row;
  }

  .col-flex-md {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  .col-flex-md .col-flex-child {
    flex-grow: 1;
  }
}

@media (min-width: 768px) {
  .row-flex-sm {
    display: flex;
    flex-pack: distribute;
    flex-direction: row;
  }

  .col-flex-sm {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  .col-flex-sm .col-flex-child {
    flex-grow: 1;
  }
}

@media (max-width: 767px) {
  .row-flex-xs {
    display: flex;
    flex-pack: distribute;
    flex-direction: row;
  }

  .col-flex-xs {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  .col-flex-xs .col-flex-child {
    flex-grow: 1;
  }
}

.l-row {
  display: flex;
  margin-left: -16px;
  margin-right: -16px;
}

@media (min-width: 768px) {
  .l-row {
    margin-left: -16px;
    margin-right: -16px;
  }
}

@media (min-width: 992px) {
  .l-row {
    margin-left: -16px;
    margin-right: -16px;
  }
}

@media (min-width: 1200px) {
  .l-row {
    margin-left: -16px;
    margin-right: -16px;
  }
}

@media (max-width: 767px) {
  .l-row {
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  .l-row {
    flex-wrap: wrap;
  }
}

@media (min-width: 1200px) {
  .l-col-lg-1-up {
    width: 8.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-lg-1-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-lg-1-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 1200px) {
  .l-col-lg-1-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) {
  .l-col-md-1-up {
    width: 8.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-1-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-1-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 1200px) {
  .l-col-md-1-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) {
  .l-col-sm-1-up {
    width: 8.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-1-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-1-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 1200px) {
  .l-col-sm-1-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) {
  .l-col-md-1-down {
    width: 8.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-1-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-1-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 1200px) {
  .l-col-md-1-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) {
  .l-col-sm-1-down {
    width: 8.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-1-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-1-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 1200px) {
  .l-col-sm-1-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .l-col-xs-1-down {
    width: 8.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-xs-1-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-xs-1-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 1200px) {
  .l-col-xs-1-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) {
  .l-col-lg-2-up {
    width: 16.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-lg-2-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-lg-2-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 1200px) {
  .l-col-lg-2-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) {
  .l-col-md-2-up {
    width: 16.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-2-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-2-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 1200px) {
  .l-col-md-2-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) {
  .l-col-sm-2-up {
    width: 16.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-2-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-2-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 1200px) {
  .l-col-sm-2-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) {
  .l-col-md-2-down {
    width: 16.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-2-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-2-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 1200px) {
  .l-col-md-2-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) {
  .l-col-sm-2-down {
    width: 16.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-2-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-2-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 1200px) {
  .l-col-sm-2-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .l-col-xs-2-down {
    width: 16.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-xs-2-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-xs-2-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 1200px) {
  .l-col-xs-2-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) {
  .l-col-lg-3-up {
    width: 25%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-lg-3-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-lg-3-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 1200px) {
  .l-col-lg-3-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) {
  .l-col-md-3-up {
    width: 25%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-3-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-3-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 1200px) {
  .l-col-md-3-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) {
  .l-col-sm-3-up {
    width: 25%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-3-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-3-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 1200px) {
  .l-col-sm-3-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) {
  .l-col-md-3-down {
    width: 25%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-3-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-3-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 1200px) {
  .l-col-md-3-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) {
  .l-col-sm-3-down {
    width: 25%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-3-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-3-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 1200px) {
  .l-col-sm-3-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .l-col-xs-3-down {
    width: 25%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-xs-3-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-xs-3-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 1200px) {
  .l-col-xs-3-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) {
  .l-col-lg-4-up {
    width: 33.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-lg-4-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-lg-4-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 1200px) {
  .l-col-lg-4-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) {
  .l-col-md-4-up {
    width: 33.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-4-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-4-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 1200px) {
  .l-col-md-4-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) {
  .l-col-sm-4-up {
    width: 33.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-4-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-4-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 1200px) {
  .l-col-sm-4-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) {
  .l-col-md-4-down {
    width: 33.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-4-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-4-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 1200px) {
  .l-col-md-4-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) {
  .l-col-sm-4-down {
    width: 33.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-4-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-4-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 1200px) {
  .l-col-sm-4-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .l-col-xs-4-down {
    width: 33.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-xs-4-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-xs-4-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 1200px) {
  .l-col-xs-4-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) {
  .l-col-lg-5-up {
    width: 41.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-lg-5-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-lg-5-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 1200px) {
  .l-col-lg-5-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) {
  .l-col-md-5-up {
    width: 41.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-5-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-5-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 1200px) {
  .l-col-md-5-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) {
  .l-col-sm-5-up {
    width: 41.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-5-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-5-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 1200px) {
  .l-col-sm-5-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) {
  .l-col-md-5-down {
    width: 41.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-5-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-5-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 1200px) {
  .l-col-md-5-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) {
  .l-col-sm-5-down {
    width: 41.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-5-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-5-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 1200px) {
  .l-col-sm-5-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .l-col-xs-5-down {
    width: 41.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-xs-5-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-xs-5-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 1200px) {
  .l-col-xs-5-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) {
  .l-col-lg-6-up {
    width: 50%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-lg-6-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-lg-6-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 1200px) {
  .l-col-lg-6-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) {
  .l-col-md-6-up {
    width: 50%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-6-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-6-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 1200px) {
  .l-col-md-6-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) {
  .l-col-sm-6-up {
    width: 50%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-6-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-6-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 1200px) {
  .l-col-sm-6-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) {
  .l-col-md-6-down {
    width: 50%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-6-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-6-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 1200px) {
  .l-col-md-6-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) {
  .l-col-sm-6-down {
    width: 50%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-6-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-6-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 1200px) {
  .l-col-sm-6-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .l-col-xs-6-down {
    width: 50%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-xs-6-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-xs-6-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 1200px) {
  .l-col-xs-6-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) {
  .l-col-lg-7-up {
    width: 58.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-lg-7-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-lg-7-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 1200px) {
  .l-col-lg-7-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) {
  .l-col-md-7-up {
    width: 58.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-7-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-7-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 1200px) {
  .l-col-md-7-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) {
  .l-col-sm-7-up {
    width: 58.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-7-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-7-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 1200px) {
  .l-col-sm-7-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) {
  .l-col-md-7-down {
    width: 58.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-7-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-7-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 1200px) {
  .l-col-md-7-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) {
  .l-col-sm-7-down {
    width: 58.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-7-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-7-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 1200px) {
  .l-col-sm-7-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .l-col-xs-7-down {
    width: 58.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-xs-7-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-xs-7-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 1200px) {
  .l-col-xs-7-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) {
  .l-col-lg-8-up {
    width: 66.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-lg-8-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-lg-8-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 1200px) {
  .l-col-lg-8-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) {
  .l-col-md-8-up {
    width: 66.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-8-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-8-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 1200px) {
  .l-col-md-8-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) {
  .l-col-sm-8-up {
    width: 66.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-8-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-8-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 1200px) {
  .l-col-sm-8-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) {
  .l-col-md-8-down {
    width: 66.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-8-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-8-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 1200px) {
  .l-col-md-8-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) {
  .l-col-sm-8-down {
    width: 66.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-8-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-8-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 1200px) {
  .l-col-sm-8-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .l-col-xs-8-down {
    width: 66.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-xs-8-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-xs-8-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 1200px) {
  .l-col-xs-8-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) {
  .l-col-lg-9-up {
    width: 75%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-lg-9-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-lg-9-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 1200px) {
  .l-col-lg-9-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) {
  .l-col-md-9-up {
    width: 75%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-9-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-9-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 1200px) {
  .l-col-md-9-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) {
  .l-col-sm-9-up {
    width: 75%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-9-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-9-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 1200px) {
  .l-col-sm-9-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) {
  .l-col-md-9-down {
    width: 75%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-9-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-9-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 1200px) {
  .l-col-md-9-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) {
  .l-col-sm-9-down {
    width: 75%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-9-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-9-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 1200px) {
  .l-col-sm-9-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .l-col-xs-9-down {
    width: 75%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-xs-9-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-xs-9-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 1200px) {
  .l-col-xs-9-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) {
  .l-col-lg-10-up {
    width: 83.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-lg-10-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-lg-10-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 1200px) {
  .l-col-lg-10-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) {
  .l-col-md-10-up {
    width: 83.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-10-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-10-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 1200px) {
  .l-col-md-10-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) {
  .l-col-sm-10-up {
    width: 83.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-10-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-10-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 1200px) {
  .l-col-sm-10-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) {
  .l-col-md-10-down {
    width: 83.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-10-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-10-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 1200px) {
  .l-col-md-10-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) {
  .l-col-sm-10-down {
    width: 83.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-10-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-10-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 1200px) {
  .l-col-sm-10-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .l-col-xs-10-down {
    width: 83.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-xs-10-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-xs-10-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 1200px) {
  .l-col-xs-10-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) {
  .l-col-lg-11-up {
    width: 91.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-lg-11-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-lg-11-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 1200px) {
  .l-col-lg-11-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) {
  .l-col-md-11-up {
    width: 91.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-11-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-11-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 1200px) {
  .l-col-md-11-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) {
  .l-col-sm-11-up {
    width: 91.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-11-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-11-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 1200px) {
  .l-col-sm-11-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) {
  .l-col-md-11-down {
    width: 91.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-11-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-11-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 1200px) {
  .l-col-md-11-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) {
  .l-col-sm-11-down {
    width: 91.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-11-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-11-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 1200px) {
  .l-col-sm-11-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .l-col-xs-11-down {
    width: 91.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-xs-11-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-xs-11-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 1200px) {
  .l-col-xs-11-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) {
  .l-col-lg-12-up {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-lg-12-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-lg-12-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 1200px) {
  .l-col-lg-12-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) {
  .l-col-md-12-up {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-12-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-12-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (min-width: 1200px) {
  .l-col-md-12-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) {
  .l-col-sm-12-up {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-12-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-12-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (min-width: 1200px) {
  .l-col-sm-12-up {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) {
  .l-col-md-12-down {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-12-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-12-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 1200px) {
  .l-col-md-12-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) {
  .l-col-sm-12-down {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-12-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-12-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 991px) and (min-width: 1200px) {
  .l-col-sm-12-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .l-col-xs-12-down {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-xs-12-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-xs-12-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 1200px) {
  .l-col-xs-12-down {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) {
  .l-col-lg-1 {
    width: 8.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-lg-1 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-lg-1 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 1200px) {
  .l-col-lg-1 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-1 {
    width: 8.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-1 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-1 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 1200px) {
  .l-col-md-1 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-1 {
    width: 8.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-1 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-1 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 1200px) {
  .l-col-sm-1 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .l-col-xs-1 {
    width: 8.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-xs-1 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-xs-1 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 1200px) {
  .l-col-xs-1 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) {
  .l-col-lg-2 {
    width: 16.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-lg-2 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-lg-2 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 1200px) {
  .l-col-lg-2 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-2 {
    width: 16.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-2 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-2 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 1200px) {
  .l-col-md-2 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-2 {
    width: 16.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-2 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-2 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 1200px) {
  .l-col-sm-2 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .l-col-xs-2 {
    width: 16.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-xs-2 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-xs-2 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 1200px) {
  .l-col-xs-2 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) {
  .l-col-lg-3 {
    width: 25%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-lg-3 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-lg-3 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 1200px) {
  .l-col-lg-3 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-3 {
    width: 25%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-3 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-3 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 1200px) {
  .l-col-md-3 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-3 {
    width: 25%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-3 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-3 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 1200px) {
  .l-col-sm-3 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .l-col-xs-3 {
    width: 25%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-xs-3 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-xs-3 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 1200px) {
  .l-col-xs-3 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) {
  .l-col-lg-4 {
    width: 33.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-lg-4 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-lg-4 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 1200px) {
  .l-col-lg-4 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-4 {
    width: 33.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-4 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-4 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 1200px) {
  .l-col-md-4 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-4 {
    width: 33.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-4 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-4 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 1200px) {
  .l-col-sm-4 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .l-col-xs-4 {
    width: 33.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-xs-4 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-xs-4 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 1200px) {
  .l-col-xs-4 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) {
  .l-col-lg-5 {
    width: 41.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-lg-5 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-lg-5 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 1200px) {
  .l-col-lg-5 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-5 {
    width: 41.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-5 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-5 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 1200px) {
  .l-col-md-5 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-5 {
    width: 41.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-5 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-5 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 1200px) {
  .l-col-sm-5 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .l-col-xs-5 {
    width: 41.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-xs-5 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-xs-5 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 1200px) {
  .l-col-xs-5 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) {
  .l-col-lg-6 {
    width: 50%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-lg-6 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-lg-6 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 1200px) {
  .l-col-lg-6 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-6 {
    width: 50%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-6 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-6 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 1200px) {
  .l-col-md-6 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-6 {
    width: 50%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-6 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-6 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 1200px) {
  .l-col-sm-6 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .l-col-xs-6 {
    width: 50%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-xs-6 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-xs-6 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 1200px) {
  .l-col-xs-6 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) {
  .l-col-lg-7 {
    width: 58.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-lg-7 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-lg-7 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 1200px) {
  .l-col-lg-7 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-7 {
    width: 58.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-7 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-7 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 1200px) {
  .l-col-md-7 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-7 {
    width: 58.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-7 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-7 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 1200px) {
  .l-col-sm-7 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .l-col-xs-7 {
    width: 58.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-xs-7 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-xs-7 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 1200px) {
  .l-col-xs-7 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) {
  .l-col-lg-8 {
    width: 66.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-lg-8 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-lg-8 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 1200px) {
  .l-col-lg-8 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-8 {
    width: 66.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-8 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-8 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 1200px) {
  .l-col-md-8 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-8 {
    width: 66.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-8 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-8 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 1200px) {
  .l-col-sm-8 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .l-col-xs-8 {
    width: 66.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-xs-8 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-xs-8 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 1200px) {
  .l-col-xs-8 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) {
  .l-col-lg-9 {
    width: 75%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-lg-9 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-lg-9 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 1200px) {
  .l-col-lg-9 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-9 {
    width: 75%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-9 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-9 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 1200px) {
  .l-col-md-9 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-9 {
    width: 75%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-9 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-9 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 1200px) {
  .l-col-sm-9 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .l-col-xs-9 {
    width: 75%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-xs-9 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-xs-9 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 1200px) {
  .l-col-xs-9 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) {
  .l-col-lg-10 {
    width: 83.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-lg-10 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-lg-10 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 1200px) {
  .l-col-lg-10 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-10 {
    width: 83.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-10 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-10 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 1200px) {
  .l-col-md-10 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-10 {
    width: 83.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-10 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-10 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 1200px) {
  .l-col-sm-10 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .l-col-xs-10 {
    width: 83.33333%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-xs-10 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-xs-10 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 1200px) {
  .l-col-xs-10 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) {
  .l-col-lg-11 {
    width: 91.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-lg-11 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-lg-11 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 1200px) {
  .l-col-lg-11 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-11 {
    width: 91.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-11 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-11 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 1200px) {
  .l-col-md-11 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-11 {
    width: 91.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-11 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-11 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 1200px) {
  .l-col-sm-11 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .l-col-xs-11 {
    width: 91.66667%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-xs-11 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-xs-11 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 1200px) {
  .l-col-xs-11 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) {
  .l-col-lg-12 {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-lg-12 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-lg-12 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1200px) and (min-width: 1200px) {
  .l-col-lg-12 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-12 {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-md-12 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-md-12 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) and (min-width: 1200px) {
  .l-col-md-12 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-12 {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-sm-12 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-sm-12 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) and (min-width: 1200px) {
  .l-col-sm-12 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .l-col-xs-12 {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 768px) and (max-width: 991px) {
  .l-col-xs-12 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 992px) and (max-width: 1199px) {
  .l-col-xs-12 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) and (min-width: 1200px) {
  .l-col-xs-12 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) {
  .l-Split--sm {
    margin-left: -16px;
    margin-right: -16px;
    display: flex;
  }

  .l-Split--sm > * {
    padding-left: 16px;
    padding-right: 16px;
  }

  .l-Split--sm.l-Split--half > .l-Split-one {
    width: 50%;
  }

  .l-Split--sm.l-Split--half > .l-Split-two {
    width: 100%;
  }

  .l-Split--sm.l-Split--thirds > .l-Split-one {
    width: 33.33333%;
  }

  .l-Split--sm.l-Split--thirds > .l-Split-two {
    width: 66.66667%;
  }

  .l-Split--sm.l-Split--thirds > .l-Split-three {
    width: 100%;
  }

  .l-Split--sm.l-Split--fourths > .l-Split-one {
    width: 25%;
  }

  .l-Split--sm.l-Split--fourths > .l-Split-two {
    width: 50%;
  }

  .l-Split--sm.l-Split--fourths > .l-Split-three {
    width: 75%;
  }

  .l-Split--sm.l-Split--fourths > .l-Split-four {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .l-Split--md {
    margin-left: -16px;
    margin-right: -16px;
    display: flex;
  }

  .l-Split--md > * {
    padding-left: 16px;
    padding-right: 16px;
  }

  .l-Split--md.l-Split--half > .l-Split-one {
    width: 50%;
  }

  .l-Split--md.l-Split--half > .l-Split-two {
    width: 100%;
  }

  .l-Split--md.l-Split--thirds > .l-Split-one {
    width: 33.33333%;
  }

  .l-Split--md.l-Split--thirds > .l-Split-two {
    width: 66.66667%;
  }

  .l-Split--md.l-Split--thirds > .l-Split-three {
    width: 100%;
  }

  .l-Split--md.l-Split--fourths > .l-Split-one {
    width: 25%;
  }

  .l-Split--md.l-Split--fourths > .l-Split-two {
    width: 50%;
  }

  .l-Split--md.l-Split--fourths > .l-Split-three {
    width: 75%;
  }

  .l-Split--md.l-Split--fourths > .l-Split-four {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .l-Split--lg {
    margin-left: -16px;
    margin-right: -16px;
    display: flex;
  }

  .l-Split--lg > * {
    padding-left: 16px;
    padding-right: 16px;
  }

  .l-Split--lg.l-Split--half > .l-Split-one {
    width: 50%;
  }

  .l-Split--lg.l-Split--half > .l-Split-two {
    width: 100%;
  }

  .l-Split--lg.l-Split--thirds > .l-Split-one {
    width: 33.33333%;
  }

  .l-Split--lg.l-Split--thirds > .l-Split-two {
    width: 66.66667%;
  }

  .l-Split--lg.l-Split--thirds > .l-Split-three {
    width: 100%;
  }

  .l-Split--lg.l-Split--fourths > .l-Split-one {
    width: 25%;
  }

  .l-Split--lg.l-Split--fourths > .l-Split-two {
    width: 50%;
  }

  .l-Split--lg.l-Split--fourths > .l-Split-three {
    width: 75%;
  }

  .l-Split--lg.l-Split--fourths > .l-Split-four {
    width: 100%;
  }
}

.l-centeredColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.l-container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

@media (min-width: 768px) {
  .l-container {
    width: 720px;
  }
}

@media (min-width: 992px) {
  .l-container {
    width: 940px;
  }
}

@media (min-width: 1200px) {
  .l-container {
    width: 1140px;
  }
}

@media (min-width: 768px) {
  .l-visible-only-xs {
    display: none;
  }
}

@media (max-width: 767px) {
  .l-hidden-xs {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .l-hidden-sm {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .l-hidden-md {
    display: none;
  }
}

@media (min-width: 1200px) {
  .l-hidden-lg {
    display: none;
  }
}

.VectorMap {
  width: 100%;
  height: 700px;
  margin-top: -64px;
  margin-bottom: -64px;
}

.VectorMap .jvectormap-container {
  height: 100%;
  width: 100%;
}

.VectorMap path {
  fill: green;
  stroke: black;
  stroke-width: 1;
}

.VectorMap-link path {
  fill: magenta;
}

.VectorMap-link:hover path {
  fill: blue;
  stroke: red;
}

.VectorMap-link:focus {
  outline: none;
}

.VectorMap-link:focus path {
  fill: orange;
  stroke: yellow;
}

.youtube-embed {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: none;
}

.youtube-embed-video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin-bottom: 16px;
}

.youtube-thumbnail-wrapper-outer {
  text-align: center;
}

.youtube-thumbnail-wrapper-inner {
  display: inline-block;
  position: relative;
}

.youtube-thumbnail-play-table {
  height: 100%;
  width: 100%;
}

.youtube-thumbnail-play-table-wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.youtube-thumbnail-play-button {
  display: inline;
  width: 50px;
  height: 38px;
  background-image: url("../../../../assets/images/youtube-play-button.png");
}

.coordinates {
  display: none;
}

.c-address-country-name {
  display: none;
}

.c-bios-item-image {
  width: 100%;
  height: auto;
}

.c-brands {
  margin: 10px 0px;
}

.c-brands-title {
  margin-bottom: 10px;
}

.c-brands-table {
  width: 100%;
}

.c-brands-table-cell {
  vertical-align: top;
}

.c-brands-table-cell:last-child .c-brands-entry {
  margin-right: 0px;
}

.c-brands-entry {
  margin-right: 15px;
}

.c-bread-crumbs {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.c-bread-crumbs-list {
  padding-left: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.c-bread-crumbs-item {
  display: inline-block;
}

.c-bread-crumbs-item + .c-bread-crumbs-item:before {
  content: '/';
  padding-left: 1ex;
  padding-right: 1ex;
}

.c-enhanced-gallery-list-item-list {
  margin-left: -16px;
  margin-right: -16px;
}

.c-enhanced-gallery-list-item-list:before,
.c-enhanced-gallery-list-item-list:after {
  content: " ";
  display: table;
}

.c-enhanced-gallery-list-item-list:after {
  clear: both;
}

.c-enhanced-gallery-list-item-wrapper {
  position: relative;
  min-height: 1px;
  padding-left: 16px;
  padding-right: 16px;
}

@media (min-width: 992px) {
  .c-enhanced-gallery-list-item-wrapper {
    float: left;
    width: 25%;
  }
}

.c-enhanced-gallery-list-item-name {
  margin-bottom: 7px;
}

.c-enhanced-gallery-list-item-image {
  width: 100%;
  height: auto;
  margin-bottom: 7px;
}

.c-enhanced-gallery-list-item-youtube-embed {
  width: 100%;
  height: 300px;
}

.c-enhanced-gallery-list-item-youtube-thumbnail-wrapper-outer {
  text-align: center;
}

.c-enhanced-gallery-list-item-youtube-thumbnail-wrapper-inner {
  display: inline-block;
  position: relative;
}

.c-enhanced-gallery-list-item-youtube-thumbnail-play-table {
  height: 100%;
  width: 100%;
}

.c-enhanced-gallery-list-item-youtube-thumbnail-play-table-wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.c-enhanced-gallery-list-item-youtube-thumbnail-play-button {
  display: inline;
  width: 50px;
  height: 38px;
  background-image: url("../../../../assets/images/youtube-play-button.png");
}

@media (min-width: 768px) {
  .c-phone-number-link {
    display: none;
  }
}

.c-phone-number-span {
  display: none;
}

.c-phone-number-span-nolink {
  display: inline;
}

@media (min-width: 768px) {
  .c-phone-number-span {
    display: inline;
  }
}

.search {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.search-label {
  position: absolute;
  align-items: center;
  top: calc((100% - 16px)/2);
  left: .875em;
  line-height: 0;
}

.search-input {
  flex: 1;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-left: 3em;
  padding-top: .85em;
  padding-bottom: .85em;
}

.search-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 1em;
  padding-right: 1em;
}

.c-social-links {
  list-style-type: none;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
}

.c-social-links-item {
  display: inline-block;
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
}

.c-uber-ride-link {
  display: flex;
  align-items: center;
}

.c-uber-ride-link > * {
  vertical-align: middle;
}

.c-uber-ride-link-icon {
  content: url("../../../../assets/images/uber_rides_icon.svg");
  width: 1em;
}

.c-uber-ride-link > * + * {
  margin-left: .5em;
}

/** @define CollapsingBanner */

.CollapsingBanner {
  background-color: #b00000;
  color: #fff;
  padding: 16px 0;
}

.CollapsingBanner i,
.CollapsingBanner em {
  font-style: italic;
}

.CollapsingBanner b,
.CollapsingBanner strong {
  font-weight: 700;
}

.CollapsingBanner a {
  text-decoration: underline;
}

.CollapsingBanner a:hover {
  text-decoration: none;
}

.CollapsingBanner ol {
  list-style-type: decimal;
}

.CollapsingBanner ul {
  list-style: disc;
}

.CollapsingBanner ol,
.CollapsingBanner ul {
  list-style-position: inside;
}

.CollapsingBanner li {
  margin-left: 25px;
}

.CollapsingBanner sup {
  vertical-align: super;
  font-size: 13px;
}

.CollapsingBanner-container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

@media (min-width: 768px) {
  .CollapsingBanner-container {
    width: 720px;
  }
}

@media (min-width: 992px) {
  .CollapsingBanner-container {
    width: 940px;
  }
}

@media (min-width: 1200px) {
  .CollapsingBanner-container {
    width: 1140px;
  }
}

@media (min-width: 768px) {
  .CollapsingBanner-container {
    display: flex;
    flex-wrap: wrap;
  }
}

.CollapsingBanner-content {
  font-size: 14px;
  line-height: 20px;
}

.CollapsingBanner-cta,
.CollapsingBanner-heading {
  font-size: 16px;
  line-height: 24px;
}

@media (min-width: 768px) {
  .CollapsingBanner-left {
    width: 66.66667%;
    flex-grow: 1;
    flex-shrink: 0;
  }
}

@media (min-width: 768px) {
  .CollapsingBanner-right {
    width: 33.33333%;
    flex-shrink: 1;
    display: flex;
  }
}

@media (min-width: 768px) {
  .CollapsingBanner-right .CollapsingBanner-content {
    margin: auto;
  }
}

.CollapsingBanner--expanded .CollapsingBanner-toggle--collapse {
  display: inline;
}

.CollapsingBanner-toggle {
  background: transparent;
}

.CollapsingBanner--expanded .CollapsingBanner-content--full {
  display: block;
}

.CollapsingBanner-cta {
  font-weight: 700;
}

@media (min-width: 768px) {
  .CollapsingBanner-cta {
    border-radius: 4px;
    padding: 8px 16px;
  }
}

.CollapsingBanner-ctaWrapper {
  margin-top: 16px;
}

@media (min-width: 768px) {
  .CollapsingBanner-ctaWrapper {
    margin-top: 0;
  }
}

.CollapsingBanner-heading {
  font-weight: 700;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.c-bread-crumbs-item::before {
  display: none;
}

/* postcss-bem-linter: ignore */

.c-bread-crumbs {
  /* postcss-bem-linter: ignore */
  /* postcss-bem-linter: ignore */
}

.c-bread-crumbs-list {
  display: flex;
}

@media (max-width: 767px) {
  .c-bread-crumbs-list {
    flex-wrap: wrap;
  }
}

.c-bread-crumbs-item {
  display: flex;
  align-items: center;
  /* postcss-bem-linter: ignore */
  /* postcss-bem-linter: ignore */
}

.c-bread-crumbs-item:not(:last-child)::after {
  content: "";
  display: block;
  width: 21px;
  height: 20px;
  background-image: url(../../../../assets/images/angle.svg);
}

.c-bread-crumbs-item:last-child {
  font-size: 12px;
  color: #231f20;
}

.c-bread-crumbs a {
  font-size: 12px;
  color: #044da2;
  /* postcss-bem-linter: ignore */
  /* postcss-bem-linter: ignore */
}

.c-bread-crumbs a:hover {
  text-decoration: underline;
}

.c-bread-crumbs a:active {
  color: #231f20;
}

/** @define Directory */

.Directory {
  /* postcss-bem-linter: ignore */
}

@media (max-width: 767px) {
  .Directory--ace {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .Directory--ace .Directory-listTeaser:not(:last-child) {
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 20px;
  }
}

.Directory-title {
  font-size: 28px;
  line-height: 28px;
  color: white;
  font-weight: 700;
  color: #008555;
}

@media (min-width: 768px) {
  .Directory-title {
    font-size: 35px;
  }
}

@media (min-width: 768px) {
  .Directory-title {
    line-height: 35px;
  }
}

.Directory-listLinkText {
  font-size: 16px;
  line-height: 22px;
  color: #044da2;
  text-decoration: underline;
  /* postcss-bem-linter: ignore */
  font-family: "Arial";
}

.Directory-listLinkText:hover {
  text-decoration: none;
}

.Directory .LocationCard {
  width: 100%;
  display: flex;
}

/** @define StateList */

/** @define CityList */

/** @define LocationList */

.Header {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 50px;
}

@media (min-width: 768px) {
  .Header {
    width: 720px;
  }
}

@media (min-width: 992px) {
  .Header {
    width: 940px;
  }
}

@media (min-width: 1200px) {
  .Header {
    width: 1140px;
  }
}

@media (min-width: 768px) {
  .Header {
    flex-wrap: wrap;
    height: 148px;
  }
}

@media (max-width: 767px) {
  .Header-mobileIcon {
    width: 24px;
    height: 24px;
  }
}

@media (max-width: 767px) {
  .Header-officer,
  .Header-mapPin {
    display: flex;
    align-items: center;
  }
}

.Header-main {
  width: 100%;
  padding: 0;
}

@media (min-width: 768px) {
  .Header-main {
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .Header-main {
    width: 720px;
  }
}

@media (min-width: 768px) and (min-width: 992px) {
  .Header-main {
    width: 940px;
  }
}

@media (min-width: 768px) and (min-width: 1200px) {
  .Header-main {
    width: 1140px;
  }
}

@media (max-width: 767px) {
  .Header-main {
    justify-content: space-between;
    align-items: center;
    padding-left: 18px;
    padding-right: 18px;
  }
}

@media (min-width: 768px) {
  .Header-logoWrapper {
    margin-top: 30px;
  }
}

.Footer-container {
  padding-top: 32px;
  border-top: 2px solid #a1c8b1;
}

@media (min-width: 768px) {
  .Footer-container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .Footer-container {
    width: 720px;
  }
}

@media (min-width: 768px) and (min-width: 992px) {
  .Footer-container {
    width: 940px;
  }
}

@media (min-width: 768px) and (min-width: 1200px) {
  .Footer-container {
    width: 1140px;
  }
}

.Footer-link {
  text-decoration: underline;
}

.Footer-link:hover {
  text-decoration: none;
}

