//
// VectorMap Component SCSS Stylesheet
//

$VectorMapStyles: (
  baseStyles: (
    fill: green,
    stroke: black,
    stroke-width: 1
  ),
  activeStyles: (
    fill: magenta
  ),
  hoverStyles: (
    fill: blue,
    stroke: red
  ),
  focusStyles:(
    fill: orange,
    stroke: yellow
  )
) !default;


.VectorMap
{
  width: 100%;
  height: 700px;
  margin-top: $grid-gutter-width*(-2);
  margin-bottom: $grid-gutter-width*(-2);

  .jvectormap-container
  {
    height: 100%;
    width: 100%;
  }

  path
  {
    @each $property, $value in map-get($VectorMapStyles, baseStyles) {
      #{$property}: #{$value};
    }
  }

  &-link
  {
    path
    {
      @each $property, $value in map-get($VectorMapStyles, activeStyles) {
        #{$property}: #{$value};
      }
    }

    &:hover
    {
      path {
        @each $property, $value in map-get($VectorMapStyles, hoverStyles) {
          #{$property}: #{$value};
        }
      }
    }
    &:focus
    {
      outline: none;

      path {
        @each $property, $value in map-get($VectorMapStyles, focusStyles) {
          #{$property}: #{$value};
        }
      }
    }
  }
}
