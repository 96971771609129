.search
{
  display: flex;
  flex-wrap: wrap;
  position: relative;

  &-label
  {
    position: absolute;
    align-items: center;
    top: calc((100% - 16px)/2); // height of search svg icon
    left: .875em;
    line-height: 0;
  }
  &-input
  {
    flex: 1;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding:
    {
      left: 3em;
      top: .85em;
      bottom: .85em;
    }
  }
  &-button
  {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding:
    {
      left: 1em;
      right: 1em;
    }
  }
}

.autocomplete-styling
{

}
