//
// Map Component SCSS Stylesheet
//

.c-map-with-pins
{
  position: relative;
  width: 100%;
  height: 400px;

  img
  {
    max-width: none;
    max-height: none;
  }

  .dir-map,
  #dir-map
  {
    width: 100%;
    height: 100%;
    line-height: initial;

    *
    {
      box-sizing: content-box;
    }

    .MicrosoftMap
    {
      margin: 0;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: auto !important;
      height: auto !important;
    }
  }
}

.mapbox-improve-map
{
  display: none;
}

.mapboxgl-ctrl-group
{
  .mapboxgl-ctrl-compass
  {
    display: none;
  }
}

.custom-icon-button
{
  background: transparent;
}

// Taken from the Google Maps css
// Override our base.scss reset for Google Maps
.gm-style img
{
  max-width: none;
  max-height: none;
}
