//
// Social Component SCSS Stylesheet
//

.c-social
{
  &-links
  {
    @include reset-list;

    &-item
    {
      display: inline-block;
    }
  }
}
