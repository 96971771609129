/** @define Locator */

$header-height-mobile: 50px !default;
$header-height-tablet: $header-height-mobile !default;
$header-height-desktop: 100px !default;
$results-width: 584px !default;

.Locator--ace
{
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;

  @include bpgte(md)
  {
    position: fixed;
    padding-top: $header-height-desktop;
  }

  .Locator
  {
    &-title
    {
      padding: 16px;
    }

    &-email
    {
      color: $brand-darkgreen;
      text-decoration: underline;

      &:hover
      {
        text-decoration: none;
      }
    }

    &-buttons
    {
      display: flex;
    }

    &-button
    {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 44px;
      height: 44px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 8px;
      padding-right: 8px;

      &[disabled]
      {
        background-color: #e7e7e7;
        cursor: default;
      }

      &:not(:last-child)
      {
        margin-right: 1px;
      }

      &:not(:first-child)
      {
        margin-left: 1px;
      }

      /* postcss-bem-linter: ignore */
      .icon
      {
        width: 18px;
        height: 18px;
      }
    }

    &-inputWrapper
    {
      display: flex;
      flex-grow: 1;
    }

    &-input
    {
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 16px;
      padding-right: 16px;
      border-top: $border-default;
      border-bottom: $border-default;
      border-left: $border-default;
      height: 44px;
      width: 100%;
    }

    &-searchTitle
    {
      padding-left: 16px;
      padding-right: 16px;
    }

    &-results
    {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 0;
      overflow: auto;
    }

    &-searchWrapper
    {
      position: sticky;
      z-index: 1;
      padding: 16px;
      display: flex;
      flex-shrink: 0;
      width: 100%;
      top: 0;
      background-color: white;

      &::after
      {
        content: "";
        z-index: -1;
        display: block;
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: -3px;
        left: 0;
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.4),
          rgba(0, 0, 0, 0)
        );
      }
    }

    &-overlay
    {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.4);
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.3s linear, opacity .3s linear;
      z-index: 2;

      &.is-active
      {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear, opacity .3s linear;
      }
    }

    &-map
    {
      display: none;

      @include bpgte(md)
      {
        display: block;
        position: fixed;
        width: calc(100% - #{$results-width});
        height: calc(100vh - #{$header-height-desktop});
        right: 0;
      }
    }

    &-resultsWrapper
    {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    &-resultsSummary
    {
      padding: 16px;
    }

    &-content
    {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      width: 100%;
      height: 100%;
      position: relative;
      background-color: white;

      @include bpgte(md)
      {
        position: relative;
        bottom: 0;
        height: auto;
        width: $results-width;
        overflow-y: scroll;
      }
    }

    &-initial,
    &-noResults
    {
      padding-top: 16px;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 80%;

      @include font-sizes(16px, 24px);
    }

    /* postcss-bem-linter: ignore */
    &-filterFieldsWrapper
    {
      position: fixed;
      display: flex;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.3s, opacity .3s linear;
      z-index: 2;

      @include bpgte(md)
      {
        left: 50%;
        top: 50%;
        bottom: auto;
        right: auto;
        transform: translate(-50%, -50%);
      }
    }

    /* postcss-bem-linter: ignore */
    &-filters.is-active
    {
      /* postcss-bem-linter: ignore */
      .Locator-filterFieldsWrapper
      {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s, opacity .3s linear;
      }
    }

    &-filterFields
    {
      width: 100%;

      @include bpgte(md)
      {
        width: 400px;
        min-height: 252px;
        max-height: 460px;
      }
    }

    &-filtersCount
    {
      margin-left: 3px;
    }

    &-form
    {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
    }
  }

  /* postcss-bem-linter: ignore */
  .c-map-with-pins-wrapper,
  .c-map-with-pins
  {
    height: 100%;
    width: 100%;
  }
}
