//
// Address Component SCSS Stylesheet
//

.coordinates
{
  display: none;
}

.c-address
{
  &-country-name
  {
    display: none;
  }
}
