/** @define Directory */

.Directory
{
  &--ace
  {
    @include bplte(xs)
    {
      padding-left: $spacing-MLO*2;
      padding-right: $spacing-MLO*2;
    }

    .Directory-listTeaser:not(:last-child)
    {
      @include bplte(xs)
      {
        padding-bottom: 0;
        border-bottom: none;
        margin-bottom: 20px;
      }
    }
  }

  &-title
  {
    @include Heading--major;
    color: $brand-lightgreen;
  }

  &-listLinkText
  {
    @include Link--body;
    font-family: $font-family-secondary;
  }

  /* postcss-bem-linter: ignore */
  .LocationCard
  {
    width: 100%;
    display: flex;
  }
}
