//
// Events Component SCSS Stylesheet
//

.c-events
{
  &-title
  {
  }

  &-wrapper
  {
  }
  &-event
  {
    margin-bottom:$grid-gutter-width / 2;

    &-details
    {
      &-date
      {
        margin-bottom:0px;
      }
      &-time
      {

      }
      &-title
      {
        margin-bottom:0px;
        &-link
        {
        }
      }
      &-type
      {
      }
      &-description
      {
        margin-top:10px;

        &.show-full-description
        {
          .c-events-section-event-details-description
          {
            &-truncated
            {
              display:none;
            }
            &-full
            {
              display:block;
            }
          }
        }

        &-truncated
        {

        }
        &-full
        {
          display:none;
        }
      }
    }
  }
}
