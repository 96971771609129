$header-height-mobile: 50px !default;
$header-height-tablet: $header-height-mobile !default;
$header-height-desktop: 100px !default;
$footer-break-point-max: min;
$footer-break-point-min: max;

html.ace
{
  min-height: 100%;
  display: flex;
  flex-direction: column;

  body
  {
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &.is-overflowHidden
    {
      overflow-y: hidden;
    }
  }

  .Main-content
  {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  .Main
  {
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .Footer
  {
    flex-shrink: 0;
  }

  .Header
  {
    display: block;
    top: 0;
    right: 0;
    left: 0;
    height: $header-height-mobile;
    z-index: 2;

    @include bp(sm,sm)
    {
      height: $header-height-tablet;
    }

    @include bpgte(md)
    {
      position: fixed;
      height: $header-height-desktop;
    }

    &.is-background
    {
      z-index: 1;
    }
  }

  .SpinnerModal
  {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    @include bp(sm,sm)
    {
      top: $header-height-tablet;
    }

    @include bpgte(md)
    {
      top: $header-height-desktop;
      width: $results-width;
      right: auto;
    }
  }

  .custom-icon-button
  {
    transition: transform 0.5s;
    transform-origin: 50% 100%;

    &:hover,
    &.is-selected
    {
      outline: none;
    }

    &.is-selected
    {
      transform: scale(1.1);
    }
  }

  .pac-container
  {
    top: 40px !important;
    left: 0 !important;
  }
}
