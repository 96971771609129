//
// Description Component SCSS Stylesheet
//

.c-description
{
  &.show-full-description
  {
    .c-description
    {
      &-truncated
      {
        display:none;
      }
      &-full
      {
        display:block;
      }
    }
  }

  &-truncated
  {

  }
  &-full
  {
    display:none;
  }
}
