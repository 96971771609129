/** @define Directory */

// Directory Ace
.Directory
{
  &--ace
  {
    @include l-container;

    padding-bottom: 4rem;
  }

  &--ace &-row
  {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1rem;
    margin-right: -1rem;
  }

  &--ace &-title
  {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 2rem;
  }

  &--ace &-content
  {
    margin-top: 2rem;
  }

  &--ace &-listTeaser
  {
    @include bplte(xs)
    {
      width: 100%;
      margin-left: 1rem;
      margin-right: 1rem;

      &:not(:last-child)
      {
        border-bottom: $border-default;
        padding-bottom: 2rem;
        margin-bottom: 2rem;
      }
    }

    @include bpgte(sm)
    {
      width: 50%;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 2rem;
    }

    @include bpgte(md)
    {
      width: 33%;
    }

    @include bpgte(lg)
    {
      width: 25%;
    }
  }

  &--ace &-listLinks
  {
    @include bp(sm)
    {
      column-count: 2;
    }

    @include bp(md)
    {
      column-count: 3;
    }

    @include bpgte(lg)
    {
      column-count: 4;
    }
  }

  &--ace &-listItem
  {
    text-transform: uppercase;
  }

  &--ace &-listLink
  {
    display: inline-block;
    margin-bottom: 1rem;

    &::after
    {
      content: attr(data-count);
      margin-left: 1ch;
    }
  }
}
