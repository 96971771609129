//
// Gallery list Component SCSS Stylesheet
//

.c-enhanced-gallery-list
{
  &-item
  {
    &-list
    {
      @include make-row();
    }
    &-wrapper
    {
      @include make-md-column(3);
    }

    &-name
    {
      margin-bottom: 7px;
    }

    &-image
    {
      width:100%;
      height:auto;
      margin-bottom: 7px;
    }
    &-youtube
    {
      &-embed
      {
        width:100%;
        height:300px;
      }

      &-thumbnail
      {
        &-wrapper
        {
          &-outer
          {
            text-align: center;
          }

          &-inner
          {
            display:inline-block;
            position:relative;
          }
        }

        &-play
        {
          &-table
          {
            height:100%;
            width:100%;

            &-wrapper
            {
              position:absolute;
              top: 0px;
              left: 0px;
              width: 100%;
              height: 100%;
            }
          }

          &-button
          {
            display:inline;
            width: 50px;
            height: 38px;
            background-image: url('assets/images/youtube-play-button.png');
          }
        }
      }
    }
  }
}
